import * as React from "react";
import styled from "styled-components";
import backgroundImage from "../images/web-bg.jpg";
import {
  AccountInfo,
  Currencies,
  DailyOrSpecialOffer,
  GoldOffer,
  LoyaltyPointOffer,
  Offers,
} from "../components/DataModels";
import { ContentBlock } from "../components/ContentBlock";
import { GoldCard } from "../components/shop/GoldCard";
import "../styles/shared.css";
import PageWrapper from "../components/shared/PageWrapper";
import ContentDiv from "../components/shared/ContentDiv";
import {
  LocalStorageInterface as LSI,
  ScreenSizes,
  configureLogoutTimer,
  isSmallScreen,
  parseActiveAccount,
} from "../shared-code";
import SpecialOfferCard from "../components/shop/SpecialOfferCard";
import DailyOfferCard from "../components/shop/DailyOfferCard";
import { AppServerAPI, WardenAPI } from "../api-calls";
import starPointIcon from "../images/StarPointIcon.png";
import goldIcon from "../images/SpecialOffer/gold.png";
import silverIcon from "../images/SpecialOffer/silver.png";
import LoyaltyOfferCard from "../components/shop/LoyaltyOffers/LoyaltyOfferCard";
import { ExpirationTypes } from "../components/shop/ExpirationBadge";
import ConfirmationPopover, {
  ConfirmationSteps,
} from "../components/shop/ConfirmationPopover";
import PlaceholderCard from "../components/shared/PlaceholderCard";
import CustomTimer from "../components/shared/CustomTimer";
import { FakeStore } from "../FAKEDATA";
import FlightPassSection from "../components/shop/FlightPassSection";
import CosmeticOfferCard from "../components/shop/CosmeticOfferCard";
import ErrorPopover from "../components/shared/ErrorPopover";
import AnniversaryOfferCard from "../components/shop/AnniversaryOfferCard";
import RedemptionPopover from "../components/shop/RedemptionPopover";
import SpecialLoyaltyOfferCard from "../components/shop/LoyaltyOffers/SpecialLoyaltyOfferCard";
import WeekdayOfferCard from "../components/shop/WeekdayOfferCard";

const Wrapper = styled.main`
  width: 100%;
  min-height: 100%;
  height: 100%;
  background: url(${backgroundImage});
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    min-height: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    background-attachment: fixed;
  }
`;

const DailyOrSpecialCardGrid = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 55px;

  @media (max-width: ${ScreenSizes.mobile}) {
    grid-template-columns: 1fr;
  }
`;

const LoyaltyCardGrid = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;

  /* @media (max-width: ${ScreenSizes.laptop}) {
    grid-template-columns: 1fr 1fr 1fr;
  } */

  @media (max-width: ${ScreenSizes.tablet}) {
    grid-template-columns: 1fr 1fr;
    padding: 20px 5px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    grid-template-columns: 1fr;
  }
`;

const GoldCardGrid = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
  width: 100%;

  @media (max-width: ${ScreenSizes.tablet}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    grid-template-columns: 1fr;
  }
`;

declare global {
  const XBuyButtonWidget: any;
  const XPayStationWidget: any;
}

function openPayStation(
  accessToken: string,
  sandbox: boolean,
  callbackFn: () => void
) {
  // https://developers.xsolla.com/doc/pay-station/integration-guide/open-payment-ui/

  var options = {
    access_token: accessToken,
    sandbox: sandbox,
  };
  var s = document.createElement("script");
  s.type = "text/javascript";
  s.async = true;
  s.src = "https://static.xsolla.com/embed/paystation/1.2.3/widget.min.js";

  s.addEventListener(
    "load",
    function (e) {
      XPayStationWidget.init(options);
      XPayStationWidget.open();
      XPayStationWidget.on(XPayStationWidget.eventTypes.CLOSE, () => {});

      XPayStationWidget.on(
        XPayStationWidget.eventTypes.STATUS_DONE,
        callbackFn
      );
    },
    false
  );
  var head = document.getElementsByTagName("head")[0];
  head.appendChild(s);
}

const TitleDiv = styled.div`
  display: grid;
  grid-template-areas:
    "title redemption"
    "description .";
  grid-template-columns: 750px calc(100% - 750px);
  align-items: start;
  justify-content: start;
  width: 100%;
  margin-top: 10px;

  & h1 {
    text-transform: uppercase;
    grid-area: title;
    font-size: 42px;
    font-weight: 600;
    text-align: left;
    margin-block: 0;
  }

  & h3 {
    grid-area: description;
    font-size: 24px;
    font-weight: 400;
    text-align: left;

    line-height: 140%;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    grid-template-areas:
      "title"
      "description"
      "redemption";
    grid-template-columns: 100%;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    margin-bottom: 30px;

    & h1,
    h3 {
      margin-inline: auto;
      text-align: center;
    }
  }
`;

const RedemptionDiv = styled.div`
  grid-area: redemption;
  display: flex;
  flex-direction: row;
  justify-content: right;
  width: 100%;

  & input {
    height: 38px;
    width: 225px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1.1px;
    padding-inline: 10px;
    padding-top: 5px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
      text-align: center;
      letter-spacing: 1px;
    }
  }

  & button {
    height: 38px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    padding: 5px 15px 0 10px;
    font-size: 18px;
    font-weight: 600;
    background-color: #f93822;
    color: white;
    text-shadow: 1px 1px 1px #00000040;
    border: none;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    justify-content: left;
    margin-bottom: 10px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    justify-content: center;
    margin-bottom: 0;
  }
`;

const RedemptionButton = styled.button``;

const LoyaltyPointHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  padding-bottom: 20px;

  @media (max-width: ${ScreenSizes.mobile}) {
    flex-direction: column;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    align-items: center;
    gap: 10px;
  }
`;

const LoyaltyPointHeaderText = styled.div`
  max-width: 820px;
  font-size: 20px;
  line-height: 140%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;

  @media (max-width: ${ScreenSizes.narrow}) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  & span {
    padding-top: 10px;
  }
`;

const AnniversaryHeaderText = styled.div`
  max-width: 820px;
  font-size: 24px;
  line-height: 140%;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;

  @media (max-width: ${ScreenSizes.narrow}) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const AnniversaryText = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoyaltyPointImg = styled.img`
  height: 44px;
  margin-block: auto;

  @media (max-width: ${ScreenSizes.narrow}) {
    display: none;
  }
`;

const LoyaltyPointImgSmall = styled.img`
  height: 44px;
  margin-block: auto;
  display: none;

  @media (max-width: ${ScreenSizes.narrow}) {
    display: block;
  }
`;

const AccentColor = styled.span`
  color: rgba(255, 141, 0, 1);
`;

const ShopTimer = styled.div`
  display: inline-flex;
  height: 35px;
  padding: 3px 16px 3px 10px;
  align-items: center;
  gap: 6px;
  border-radius: 36px;
  background: rgba(0, 0, 0, 0.7);
  font-weight: 600;
  font-size: 18px;
  color: white;

  & span {
    padding-top: 3px;
    width: 60px;
  }
`;

const TimerImg = styled.img`
  height: 20px;
  aspect-ratio: 1 / 1;
`;

const SubHeaderWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 60px;
`;

const SubHeaderResource = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-right: 13px;

  & span {
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
  }

  & img {
    height: 30px;
  }
`;

interface MyState {
  accounts: AccountInfo[] | undefined;
  activeAccountId: string | undefined;
  timeOutNumber: number | undefined;
  errorMessage: string | undefined;
  errorMessageShown: boolean;
  errorCode?: string | undefined;
  offers: Offers | undefined;
  currencies: Currencies | undefined;
  awaitingOffers: boolean;
  confirmationData: {
    step: string;
    closeFn: () => void;
    purchaseFn?: () => void;
    itemName?: string;
    starPointsRequired?: number;
    starPointsAwarded?: number;
  };
  starPointOfferProcessing: boolean;
  showLogin?: number | undefined;
  redemptionText?: string | undefined;
  redemptionData: {
    visible: boolean;
    codeName: string;
  };
}

export default class Shop extends React.Component<{}, MyState> {
  constructor(props: {} | Readonly<{}>) {
    super(props);

    this.state = {
      accounts: LSI.getAccounts(),
      activeAccountId: LSI.getActiveAccountId(),
      timeOutNumber: undefined,
      errorMessage: undefined,
      errorCode: undefined,
      errorMessageShown: false,
      offers: LSI.getOffers(),
      currencies: LSI.getCurrencies(),
      awaitingOffers: false,
      confirmationData: {
        step: ConfirmationSteps.hidden,
        closeFn: () => {
          const newConfirmationData = this.state.confirmationData;
          newConfirmationData.step = ConfirmationSteps.hidden;
          this.setState({ confirmationData: newConfirmationData });
        },
        purchaseFn: undefined,
        itemName: undefined,
        starPointsRequired: undefined,
        starPointsAwarded: undefined,
      },
      starPointOfferProcessing: false,
      showLogin: undefined,
      redemptionText: undefined,
      redemptionData: {
        visible: false,
        codeName: "",
      },
    };

    this.handleAPIError = this.handleAPIError.bind(this);
  }

  componentDidMount(): void {
    window.addEventListener("focus", () => this.setupTimeoutNumber());
    this.setupTimeoutNumber();
  }

  componentDidUpdate(
    prevProps: Readonly<{}>,
    prevState: Readonly<MyState>
  ): void {
    if (prevState.activeAccountId != this.state.activeAccountId) {
      this.setupTimeoutNumber();
    }
  }

  componentWillUnmount(): void {
    if (this.state.timeOutNumber != undefined) {
      window.clearTimeout(this.state.timeOutNumber);
    }
  }

  handleAPIError(error: string, requestUniqueId?: string | undefined): void {
    const newConfirmationData = this.state.confirmationData;
    newConfirmationData.step = ConfirmationSteps.hidden;
    if (requestUniqueId != undefined) {
      this.setState({
        errorMessage: error,
        errorCode: requestUniqueId,
        errorMessageShown: true,
        awaitingOffers: false,
        starPointOfferProcessing: false,
        confirmationData: newConfirmationData,
      });
    } else {
      this.setState({
        errorMessage: error,
        errorMessageShown: true,
        awaitingOffers: false,
        starPointOfferProcessing: false,
        confirmationData: newConfirmationData,
      });
    }
  }

  setupTimeoutNumber(): void {
    if (this.state.timeOutNumber != undefined) {
      window.clearTimeout(this.state.timeOutNumber);
    }
    const timeOutNumber = configureLogoutTimer(() => {
      const activeAccountId = this.state.activeAccountId;
      if (activeAccountId != undefined && this.state.awaitingOffers == false) {
        this.setState({ awaitingOffers: true });
        AppServerAPI.getXsollaOffers(
          activeAccountId,
          (offers, currencies, isSandboxMode) => {
            LSI.setOffers(offers, activeAccountId);
            LSI.setCurrencies(currencies, activeAccountId);
            LSI.setIsSandboxMode(isSandboxMode);
            this.setState({
              offers: offers,
              currencies: currencies,
              awaitingOffers: false,
            });
          },
          this.handleAPIError
        );
      }
    });
    if (timeOutNumber != undefined) {
      this.setState({ timeOutNumber: timeOutNumber });
    }
  }

  sendCodeForRedemption(): void {
    const accountId = this.state.activeAccountId;
    const redemptionCode = this.state.redemptionText;
    if (accountId == undefined) {
      this.setState({ showLogin: Date.now() });
    } else if (redemptionCode == undefined || redemptionCode.length == 0) {
      this.handleAPIError("Please Enter a Code before Submitting");
    } else {
      AppServerAPI.claimRedemptionCode(
        accountId,
        redemptionCode,
        (res) => {
          const newRedemptionData = this.state.redemptionData;
          newRedemptionData.codeName = res;
          newRedemptionData.visible = true;
          this.setState({ redemptionData: newRedemptionData });
        },
        this.handleAPIError
      );
    }
  }

  subHeader = (currencies: Currencies | undefined) => {
    if (currencies != undefined) {
      return (
        <SubHeaderWrapper>
          <SubHeaderResource>
            <img src={starPointIcon} alt="" />
            <span>{currencies.loyaltyPoints}</span>
          </SubHeaderResource>
          <SubHeaderResource>
            <img src={goldIcon} alt="" />
            <span>{currencies.gold}</span>
          </SubHeaderResource>
          <SubHeaderResource>
            <img src={silverIcon} alt="" />
            <span>{currencies.silver}</span>
          </SubHeaderResource>
        </SubHeaderWrapper>
      );
    }
  };

  render() {
    const loyaltyPointStoreRefresh =
      this.state.offers?.loyaltyPointStore.currentPeriodExpiration || undefined;
    const account = parseActiveAccount(
      this.state.accounts,
      this.state.activeAccountId
    );

    //ANNIVERSARY LOYALTY POINT OFFER CODE
    const anniversaryOffersExist =
      this.state.offers != undefined &&
      this.state.offers?.loyaltyPointStore.offers.filter((offer) =>
        offer.offerId.includes("PilotIcon")
      ).length > 0;

    return (
      <PageWrapper
        activeRoute={"/shop"}
        backgroundImage={backgroundImage}
        fullScreen={false}
        subHeader={this.subHeader(this.state.currencies)}
        forceLogin={false}
        activeAccount={account}
        accounts={this.state.accounts}
        updateAccounts={(accounts: AccountInfo[]): void => {
          this.setState({ accounts: accounts });
        }}
        updateActiveAccount={(account: AccountInfo): void => {
          this.setState({
            activeAccountId: account.accountId,
            currencies: LSI.getCurrencies(account.accountId),
            offers: LSI.getOffers(account.accountId),
          });
        }}
        removeAccountsAndActive={() =>
          this.setState({ accounts: undefined, activeAccountId: undefined })
        }
        showLogin={this.state.showLogin}
        errorFn={(message, code) =>
          this.setState({
            errorMessage: message,
            errorCode: code,
            errorMessageShown: true,
          })
        }
      >
        <ErrorPopover
          errorMessageShown={this.state.errorMessageShown}
          errorMessage={this.state.errorMessage}
          errorCode={this.state.errorCode}
          closeFn={() => this.setState({ errorMessageShown: false })}
        />
        <RedemptionPopover
          {...this.state.redemptionData}
          closeFn={() => {
            const newRedemptionData = this.state.redemptionData;
            newRedemptionData.visible = false;
            newRedemptionData.codeName = "";
            this.setState({ redemptionData: newRedemptionData });
          }}
        />
        <ConfirmationPopover {...this.state.confirmationData} />
        {this.state.activeAccountId != undefined ? (
          <ContentDiv
            smallDevice={isSmallScreen()}
            hasSubHeader={true}
            fullScreen={false}
          >
            <TitleDiv>
              <h1>metalstorm shop</h1>
              <RedemptionDiv>
                <input
                  onChange={(e) =>
                    this.setState({ redemptionText: e.target.value })
                  }
                  type="text"
                  placeholder="ENTER PROMO CODE"
                  onKeyUp={(e) => {
                    if (e.code == "Enter") {
                      this.sendCodeForRedemption();
                    }
                  }}
                />
                <RedemptionButton
                  onClick={(e) => {
                    e.preventDefault;
                    this.sendCodeForRedemption();
                  }}
                >
                  REDEEM
                </RedemptionButton>
              </RedemptionDiv>
              <h3>
                Starform's official online marketplace for in-game Metalstorm
                products. Support the developers and get the best deals — direct
                from the source!
              </h3>
            </TitleDiv>
            {this.state.offers?.flightPassOffers.map((offer) => (
              <FlightPassSection
                offer={offer}
                purchaseFn={() => {
                  const accountId = LSI.getActiveAccountId();
                  const sandbox = LSI.getIsSandboxMode();
                  if (accountId != undefined) {
                    AppServerAPI.makeXsollaPurchase(
                      accountId,
                      offer.sku,
                      sandbox,
                      async (accessToken) =>
                        openPayStation(accessToken, sandbox, () => {
                          const previousConfirmationData =
                            this.state.confirmationData;
                          previousConfirmationData.step =
                            ConfirmationSteps.deliveredPaid;
                          previousConfirmationData.starPointsAwarded =
                            offer.loyaltyPoints;
                          this.setState({
                            confirmationData: previousConfirmationData,
                          });
                          this.setupTimeoutNumber();
                        }),
                      this.handleAPIError,
                      offer.uniqueId
                    );
                  }
                }}
              />
            ))}

            <LoyaltyPointImgSmall src={starPointIcon} alt="" />
            <ContentBlock id={"rewards"} title="Web Shop Rewards!">
              <LoyaltyPointHeader>
                <LoyaltyPointHeaderText>
                  <LoyaltyPointImg src={starPointIcon} alt="" />
                  <span>
                    Purchases you make on the Web Shop award{" "}
                    <AccentColor>Star Points</AccentColor>. Redeem your Star
                    Points on the Web Shop for FREE REWARDS!
                  </span>
                </LoyaltyPointHeaderText>
                {loyaltyPointStoreRefresh != undefined && (
                  <ShopTimer>
                    <TimerImg src={ExpirationTypes.expiry} />
                    <span>
                      <CustomTimer
                        expirationTime={loyaltyPointStoreRefresh}
                        serverOffset={this.state.offers?.serverOffset || 0}
                      />
                    </span>
                  </ShopTimer>
                )}
              </LoyaltyPointHeader>
              {anniversaryOffersExist && (
                <LoyaltyPointHeader>
                  <AnniversaryText>
                    <AnniversaryHeaderText>
                      Exclusive Winter Holiday 2024 Profile Icons
                    </AnniversaryHeaderText>
                    <div>
                      <LoyaltyPointHeaderText>
                        Unlock the limited edition, Winter themed icons, only
                        available in the Star Point Shop 12/25 - 1/13.
                      </LoyaltyPointHeaderText>
                    </div>
                  </AnniversaryText>
                </LoyaltyPointHeader>
              )}
              <LoyaltyCardGrid>
                {this.state.offers?.loyaltyPointStore.offers
                  .sort((a, b) => a.sortOrder - b.sortOrder)
                  .map((offer) => {
                    const activeAccount = LSI.getActiveAccountId();
                    let clickFn = () => {};
                    if (activeAccount != undefined) {
                      clickFn = () => {
                        const previousConfirmationData =
                          this.state.confirmationData;
                        previousConfirmationData.step =
                          ConfirmationSteps.confirmation;
                        previousConfirmationData.starPointsRequired =
                          offer.loyaltyPointCost;
                        previousConfirmationData.itemName = offer.award.text;
                        previousConfirmationData.purchaseFn = () => {
                          if (this.state.starPointOfferProcessing == false) {
                            this.setState({ starPointOfferProcessing: true });
                            AppServerAPI.makeLoyaltyPointPurchase(
                              activeAccount,
                              offer.offerId,
                              () => {
                                const updatedConfirmationData =
                                  this.state.confirmationData;
                                updatedConfirmationData.step =
                                  ConfirmationSteps.deliveredFree;
                                updatedConfirmationData.starPointsRequired =
                                  undefined;
                                updatedConfirmationData.itemName = undefined;
                                updatedConfirmationData.purchaseFn = () => null;
                                this.setState({
                                  confirmationData: previousConfirmationData,
                                  starPointOfferProcessing: false,
                                });
                                this.setupTimeoutNumber();
                              },
                              this.handleAPIError
                            );
                          }
                        };
                        this.setState({
                          confirmationData: previousConfirmationData,
                        });
                      };
                    }
                    if (offer.expirationTime == undefined) {
                      return (
                        <LoyaltyOfferCard
                          offer={offer}
                          pointsSpent={
                            this.state.offers?.loyaltyPointStore
                              .loyaltyPointsSpent || 0
                          }
                          purchaseFn={clickFn}
                          pointWalletTotal={parseInt(
                            this.state.currencies?.loyaltyPoints.replace(
                              ",",
                              ""
                            ) || "0"
                          )}
                          anniversaryOffer={offer.limitedTimeOffer}
                        />
                      );
                    } else {
                      return (
                        <SpecialLoyaltyOfferCard
                          offer={offer}
                          pointsSpent={
                            this.state.offers?.loyaltyPointStore
                              .loyaltyPointsSpent || 0
                          }
                          purchaseFn={clickFn}
                          pointWalletTotal={parseInt(
                            this.state.currencies?.loyaltyPoints.replace(
                              ",",
                              ""
                            ) || "0"
                          )}
                          anniversaryOffer={offer.limitedTimeOffer}
                        />
                      );
                    }
                  })}
              </LoyaltyCardGrid>
            </ContentBlock>
            <ContentBlock id={"dailies"} title="Daily Deals">
              <DailyOrSpecialCardGrid>
                {this.state.offers?.dailyOffers.map(
                  (offer: DailyOrSpecialOffer) => {
                    if (offer.subType == "weekday") {
                      return (
                        <WeekdayOfferCard
                          purchaseFn={() => {}}
                          offer={offer}
                          onClick={() => {
                            const accountId = LSI.getActiveAccountId();
                            const sandbox = LSI.getIsSandboxMode();
                            if (accountId != undefined) {
                              AppServerAPI.makeXsollaPurchase(
                                accountId,
                                offer.sku,
                                sandbox,
                                async (accessToken) =>
                                  openPayStation(accessToken, sandbox, () => {
                                    const previousConfirmationData =
                                      this.state.confirmationData;
                                    previousConfirmationData.step =
                                      ConfirmationSteps.deliveredPaid;
                                    previousConfirmationData.starPointsAwarded =
                                      offer.loyaltyPoints;
                                    this.setState({
                                      confirmationData:
                                        previousConfirmationData,
                                    });
                                    this.setupTimeoutNumber();
                                  }),
                                this.handleAPIError,
                                offer.uniqueId,
                                "Weekday"
                              );
                            }
                          }}
                        />
                      );
                    } else {
                      let clickFn = () => {};
                      if (offer.consumed != true) {
                        const accountId = LSI.getActiveAccountId();
                        if (offer.price.amount == 0) {
                          clickFn = () => {
                            if (accountId != undefined) {
                              AppServerAPI.makeDailyOfferPurchase(
                                accountId,
                                offer.sku,
                                async () => {
                                  const previousConfirmationData =
                                    this.state.confirmationData;
                                  previousConfirmationData.step =
                                    ConfirmationSteps.deliveredFree;
                                  this.setState({
                                    confirmationData: previousConfirmationData,
                                  });
                                  this.setupTimeoutNumber();
                                },
                                this.handleAPIError
                              );
                            }
                          };
                        } else {
                          clickFn = () => {
                            const sandbox = LSI.getIsSandboxMode();
                            if (accountId != undefined) {
                              AppServerAPI.makeXsollaPurchase(
                                accountId,
                                offer.sku,
                                sandbox,
                                async (accessToken) =>
                                  openPayStation(accessToken, sandbox, () => {
                                    const previousConfirmationData =
                                      this.state.confirmationData;
                                    previousConfirmationData.step =
                                      ConfirmationSteps.deliveredPaid;
                                    previousConfirmationData.starPointsAwarded =
                                      offer.loyaltyPoints;
                                    this.setState({
                                      confirmationData:
                                        previousConfirmationData,
                                    });
                                    this.setupTimeoutNumber();
                                  }),
                                this.handleAPIError,
                                offer.uniqueId
                              );
                            }
                          };
                        }
                      }
                      return (
                        <DailyOfferCard
                          purchaseFn={() => {}}
                          offer={offer}
                          onClick={clickFn}
                        />
                      );
                    }
                  }
                )}
                {this.state.offers?.dailyOffers != undefined ? (
                  this.state.offers?.dailyOffers.length == 0 ? (
                    <PlaceholderCard
                      {...{
                        h: 315,
                        w: 685,
                        text: "Check back often to find new personalized offers!",
                        fullWidth: true,
                      }}
                    />
                  ) : this.state.offers?.dailyOffers.length % 2 != 0 ? (
                    <PlaceholderCard
                      {...{
                        h: 315,
                        w: 685,
                        text: "Check back often to find new personalized offers!",
                        fullWidth: false,
                      }}
                    />
                  ) : null
                ) : null}
              </DailyOrSpecialCardGrid>
            </ContentBlock>
            {this.state.offers != undefined &&
            this.state.offers.specialOffers != undefined &&
            this.state.offers.specialOffers.length > 0 ? (
              <ContentBlock id={"specials"} title="Special Offers">
                <DailyOrSpecialCardGrid>
                  {this.state.offers?.specialOffers.map(
                    (offer: DailyOrSpecialOffer) => {
                      if (offer.subType == "cosmeticOffer") {
                        return (
                          <CosmeticOfferCard
                            purchaseFn={() => {}}
                            offer={offer}
                            onClick={() => {
                              const accountId = LSI.getActiveAccountId();
                              const sandbox = LSI.getIsSandboxMode();
                              if (accountId != undefined) {
                                AppServerAPI.makeXsollaPurchase(
                                  accountId,
                                  offer.sku,
                                  sandbox,
                                  async (accessToken) =>
                                    openPayStation(accessToken, sandbox, () => {
                                      const previousConfirmationData =
                                        this.state.confirmationData;
                                      previousConfirmationData.step =
                                        ConfirmationSteps.deliveredPaid;
                                      previousConfirmationData.starPointsAwarded =
                                        offer.loyaltyPoints;
                                      this.setState({
                                        confirmationData:
                                          previousConfirmationData,
                                      });
                                      this.setupTimeoutNumber();
                                    }),
                                  this.handleAPIError,
                                  offer.uniqueId
                                );
                              }
                            }}
                          />
                        );
                      } else if (offer.subType == "Anniversary") {
                        return (
                          <AnniversaryOfferCard
                            offer={offer}
                            purchaseFn={
                              offer.consumed
                                ? () => {}
                                : () => {
                                    const accountId = LSI.getActiveAccountId();
                                    const sandbox = LSI.getIsSandboxMode();
                                    if (accountId != undefined) {
                                      AppServerAPI.makeXsollaPurchase(
                                        accountId,
                                        offer.sku,
                                        sandbox,
                                        async (accessToken) =>
                                          openPayStation(
                                            accessToken,
                                            sandbox,
                                            () => {
                                              const previousConfirmationData =
                                                this.state.confirmationData;
                                              previousConfirmationData.step =
                                                ConfirmationSteps.deliveredPaid;
                                              previousConfirmationData.starPointsAwarded =
                                                offer.loyaltyPoints;
                                              this.setState({
                                                confirmationData:
                                                  previousConfirmationData,
                                              });
                                              this.setupTimeoutNumber();
                                            }
                                          ),
                                        this.handleAPIError,
                                        offer.uniqueId
                                      );
                                    }
                                  }
                            }
                          />
                        );
                      } else {
                        return (
                          <SpecialOfferCard
                            purchaseFn={() => {}}
                            offer={offer}
                            onClick={() => {
                              const accountId = LSI.getActiveAccountId();
                              const sandbox = LSI.getIsSandboxMode();
                              if (accountId != undefined) {
                                AppServerAPI.makeXsollaPurchase(
                                  accountId,
                                  offer.sku,
                                  sandbox,
                                  async (accessToken) =>
                                    openPayStation(accessToken, sandbox, () => {
                                      const previousConfirmationData =
                                        this.state.confirmationData;
                                      previousConfirmationData.step =
                                        ConfirmationSteps.deliveredPaid;
                                      previousConfirmationData.starPointsAwarded =
                                        offer.loyaltyPoints;
                                      this.setState({
                                        confirmationData:
                                          previousConfirmationData,
                                      });
                                      this.setupTimeoutNumber();
                                    }),
                                  this.handleAPIError,
                                  offer.uniqueId
                                );
                              }
                            }}
                          />
                        );
                      }
                    }
                  )}
                  {this.state.offers?.specialOffers.length == 0 ? (
                    <PlaceholderCard
                      {...{
                        h: 315,
                        w: 685,
                        text: "Check back often to find new personalized offers!",
                        fullWidth: true,
                      }}
                    />
                  ) : this.state.offers?.specialOffers.length % 2 != 0 ? (
                    <PlaceholderCard
                      {...{
                        h: 315,
                        w: 685,
                        text: "Check back often to find new personalized offers!",
                        fullWidth: false,
                      }}
                    />
                  ) : null}
                </DailyOrSpecialCardGrid>
              </ContentBlock>
            ) : null}
            <ContentBlock id={"gold"} title="Gold">
              <GoldCardGrid>
                {this.state.offers?.goldOffers.map((offer) => {
                  let flagText = "";
                  if (offer.name == "170 Gold") {
                    flagText = "Popular";
                  } else if (offer.name == "2000 Gold") {
                    flagText = "Best Value";
                  }
                  return (
                    <GoldCard
                      flagText={flagText}
                      purchaseFn={() => {
                        const accountId = LSI.getActiveAccountId();
                        const sandbox = LSI.getIsSandboxMode();
                        if (accountId != undefined) {
                          AppServerAPI.makeXsollaPurchase(
                            accountId,
                            offer.sku,
                            sandbox,
                            async (accessToken) =>
                              openPayStation(accessToken, sandbox, () => {
                                const previousConfirmationData =
                                  this.state.confirmationData;
                                previousConfirmationData.step =
                                  ConfirmationSteps.deliveredPaid;
                                previousConfirmationData.starPointsAwarded =
                                  offer.attributes[0].values[0].value;
                                this.setState({
                                  confirmationData: previousConfirmationData,
                                });
                                this.setupTimeoutNumber();
                              }),
                            this.handleAPIError
                          );
                        }
                      }}
                      loyaltyPoints={0}
                      offer={offer}
                    />
                  );
                })}
              </GoldCardGrid>
            </ContentBlock>
          </ContentDiv>
        ) : (
          <ContentDiv
            smallDevice={isSmallScreen()}
            hasSubHeader={true}
            fullScreen={false}
          >
            <TitleDiv>
              <h1>metalstorm shop</h1>
              <RedemptionDiv>
                <input
                  onChange={(e) =>
                    this.setState({ redemptionText: e.target.value })
                  }
                  type="text"
                  placeholder="ENTER PROMO CODE"
                />
                <RedemptionButton
                  onClick={(e) => {
                    e.preventDefault;
                    this.setState({ showLogin: Date.now() });
                  }}
                >
                  REDEEM
                </RedemptionButton>
              </RedemptionDiv>
              <h3>
                Starform's official online marketplace for in-game Metalstorm
                products. Support the developers and get the best deals — direct
                from the source!
              </h3>
            </TitleDiv>
            <LoyaltyPointImgSmall src={starPointIcon} alt="" />
            <ContentBlock id={"rewards"} title="Web Shop Rewards!">
              <LoyaltyPointHeader>
                <LoyaltyPointHeaderText>
                  <LoyaltyPointImg src={starPointIcon} alt="" />
                  <span>
                    Purchases you make on the Web Shop award{" "}
                    <AccentColor>Star Points</AccentColor>. Redeem your Star
                    Points on the Web Shop for FREE REWARDS!
                  </span>
                </LoyaltyPointHeaderText>
                {loyaltyPointStoreRefresh != undefined && (
                  <ShopTimer>
                    <TimerImg src={ExpirationTypes.expiry} />
                    <span>
                      <CustomTimer
                        expirationTime={
                          FakeStore.loyaltyPointStore.currentPeriodExpiration
                        }
                        serverOffset={0}
                      />
                    </span>
                  </ShopTimer>
                )}
              </LoyaltyPointHeader>
              <LoyaltyCardGrid>
                {FakeStore.loyaltyPointStore.offers
                  .sort(
                    (a: { sortOrder: number }, b: { sortOrder: number }) =>
                      a.sortOrder - b.sortOrder
                  )
                  .map((offer: LoyaltyPointOffer) => {
                    return (
                      <LoyaltyOfferCard
                        offer={offer}
                        pointsSpent={300}
                        purchaseFn={() =>
                          this.setState({ showLogin: Date.now() })
                        }
                        pointWalletTotal={1000}
                        anniversaryOffer={false}
                      />
                    );
                  })}
              </LoyaltyCardGrid>
            </ContentBlock>
            <ContentBlock id={"dailies"} title="Daily Deals">
              <DailyOrSpecialCardGrid>
                {FakeStore.dailyOffers.map((offer: DailyOrSpecialOffer) => {
                  if (offer.subType == "weekday") {
                    return (
                      <WeekdayOfferCard
                        purchaseFn={() => {}}
                        offer={offer}
                        onClick={() => this.setState({ showLogin: Date.now() })}
                      />
                    );
                  } else {
                    return (
                      <DailyOfferCard
                        purchaseFn={() => {}}
                        offer={offer}
                        onClick={() => this.setState({ showLogin: Date.now() })}
                      />
                    );
                  }
                })}
              </DailyOrSpecialCardGrid>
            </ContentBlock>
            <ContentBlock id={"gold"} title="Gold">
              <GoldCardGrid>
                {FakeStore.goldOffers.map((offer: GoldOffer) => {
                  let flagText = "";
                  if (offer.name == "170 Gold") {
                    flagText = "Popular";
                  } else if (offer.name == "2000 Gold") {
                    flagText = "Best Value";
                  }
                  return (
                    <GoldCard
                      flagText={flagText}
                      purchaseFn={() => {
                        this.setState({ showLogin: Date.now() });
                      }}
                      loyaltyPoints={0}
                      offer={offer}
                    />
                  );
                })}
              </GoldCardGrid>
            </ContentBlock>
          </ContentDiv>
        )}
      </PageWrapper>
    );
  }
}
