import * as React from "react";
import styled from "styled-components";
import { OfferHeader } from "./OfferHeader";
import { OfferFooter } from "./OfferFooter";
import bgDesktop from "../../images/SpecialOffer/backgrounds/bg-desktop-daily.png";
import bgTablet from "../../images/SpecialOffer/backgrounds/bg-tablet-daily.png";
import bgMobile from "../../images/SpecialOffer/backgrounds/bg-mobile-daily.png";
import bgNarrow from "../../images/SpecialOffer/backgrounds/bg-narrow-daily.png";
import { ScreenSizes } from "../../shared-code";
import { ExpirationTypes } from "./ExpirationBadge";
import { DailyOrSpecialOffer } from "../DataModels";
import Ferris from "../../images/WeekdayOffer/ferris.png";

const OuterWrapper = styled.div`
  position: relative;
  max-width: 685px;
  height: 315px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 685px;
  height: 315px;
  background-position: right;
  background-size: cover;
  border-radius: 8px;
  cursor: pointer;
  justify-self: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 270px;
  padding: 95px 15px 5px 15px;
  width: 300px;
  z-index: 1;

  @media (max-width: ${ScreenSizes.tablet}) {
    width: 260px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    width: 300px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    width: 100%;
  }
`;

const Name = styled.div`
  height: 76px;
  font-size: 36px;
  font-weight: 600;
  line-height: 105%;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  z-index: 0;

  @media (max-width: ${ScreenSizes.tablet}) {
    width: 250px;
    font-size: 34px;
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    width: 340px;
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    width: 180px;
  }
`;

const Awards = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const AwardLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 43px;
  background: rgba(2, 31, 60, 0.35);
  gap: 5px;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  text-shadow: 0.5px 1px 0px rgba(0, 0, 0, 0.4);
`;

const AwardText = styled.span<{ leftPadding?: boolean | false }>`
  display: inline-flex;
  gap: 5px;
  padding-top: 3px;
  padding-left: ${(props) => (props.leftPadding ? "5px" : 0)};
`;

const IconCell = styled.div`
  width: 43px;
  height: 43px;
  background: rgba(255, 255, 255, 0.12);

  & > img {
    height: 43px;
  }
`;

const Sticker = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  background-color: #f34a4a;
  border-radius: 50%;
  height: 92px;
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.6);
  font-weight: 600;
  padding-top: 10px;
  text-shadow: 0.5px 1px 0 #000;
  z-index: 1;

  @media (max-width: ${ScreenSizes.narrow}) {
    height: 64px;
    padding-top: 5px;
  }
`;

const StickerMain = styled.span`
  font-size: 36px;

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 24px;
  }
`;

const StickerSub = styled.span`
  font-size: 22px;

  @media (max-width: ${ScreenSizes.narrow}) {
    font-size: 20px;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: -50%;
  min-height: 315px;
  width: 150%;
  background-repeat: no-repeat;

  @media (max-width: ${ScreenSizes.tablet}) {
    background: url(${bgTablet});
  }

  @media (max-width: ${ScreenSizes.mobile}) {
    background: url(${bgMobile});
  }

  @media (max-width: ${ScreenSizes.narrow}) {
    background: url(${bgNarrow});
  }
`;

const AwardImage = styled.img`
  position: absolute;
  top: 50px;
  right: min(75px, calc(50% - 100px));
  width: 200px;
  background-repeat: no-repeat;
`;

const FerrisImg = styled.img`
  position: absolute;
  top: 0;
  right: -100px;
  height: 315px;
  background-repeat: no-repeat;

  @media (max-width: ${ScreenSizes.narrow}) {
    top: 20px;
  }
`;

export interface WeekdayOfferCardProps {
  offer: DailyOrSpecialOffer;
  purchaseFn: () => void;
  onClick: () => void;
}

export default (props: WeekdayOfferCardProps) => {
  const imageCount = props.offer.awards.length;

  let backgroundURL = bgDesktop;

  return (
    <OuterWrapper>
      <OfferHeader
        loyaltyPoints={props.offer.loyaltyPoints}
        expiration={props.offer.expires}
        expirationType={ExpirationTypes.expiry}
        serverOffset={props.offer.serverOffset}
        stickerText={props.offer.stickerText}
      />
      <Wrapper onClick={props.onClick}>
        <CardBody>
          <Name>{props.offer.name}</Name>
          <Awards>
            {props.offer.awards.map((award) => {
              const showIcon = award.icon != null && award.icon != undefined;
              return (
                <AwardLine>
                  {showIcon ? (
                    <IconCell>
                      <img src={award.icon} />
                    </IconCell>
                  ) : null}
                  <AwardText leftPadding={!showIcon}>
                    <span>{award.amount}</span> <span>{award.text}</span>
                  </AwardText>
                </AwardLine>
              );
            })}
          </Awards>
        </CardBody>
        <BackgroundImage src={backgroundURL} />
        <FerrisImg src={Ferris} />
        <OfferFooter stickerText={null} {...props.offer.price} />
      </Wrapper>
    </OuterWrapper>
  );
};
